import { PortraitAssets } from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGModeURL } from "@src/ducks/slices/gmode.slice";
import Loading from "components/loading/Loading";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { View } from "react-native";
import colors from "theme/colors";
import useCacheImage from "utils/cache-helper";
import { isAndroidWeb } from "utils/device-helper";
import useOrientationListener from "utils/orientation-helper";
import useStyles from "./styles.css";

const Portrait = () => {
  const styles = useStyles();
  const isAndroid = isAndroidWeb();
  const document: any = window.document;
  const orientation = useOrientationListener();
  const appIsReady = useCacheImage(PortraitAssets);
  const gameLink = useAppSelector(selectedGModeURL);
  const [animationData, setAnimationData] = useState();
  const onFullScreen = () => document.body.requestFullscreen();

  useEffect(() => {
    import("@assets/json/landscape").then(async(res: any) => {
      setAnimationData(res.default);
    });
  }, []);

  useEffect(() => {
    if(orientation === "LANDSCAPE" && isAndroid){
      onFullScreen();
    }
  }, [orientation, isAndroid]);

  if (orientation === "LANDSCAPE" || gameLink) {
    return null;
  }

  if (!animationData || !appIsReady) 
    return <Loading customStyle={{backgroundColor: colors.translucent }} isLoading />;

  return (
    <View style={[styles.container]}>
      <Lottie
        play
        loop
        speed={1.2}
        animationData={animationData}
        style={styles.lottie_style}
      />
    </View>
  );
};

export default React.memo(Portrait);
