import { useRoute } from "@react-navigation/native";
import { useGModeService } from "@src/ducks/hooks";
import useSoundService from "@src/ducks/hooks/useSoundService";
import * as ScreenOrientation from "expo-screen-orientation";
import React, { useEffect } from "react";
import { View } from "react-native";
import GameView from "./GameView";
import Drawer from "./actionbutton";
import CoinsDialog from "./coins-dialog";
import GModeProvider from "./provider";
import useStyles from "./styles.css";

const GameMode = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const { bgsoundPause, bgsoundPlay } = useSoundService();
  const { onGModeRequest, onLeaveGModeRequest } = useGModeService();

  const lockScreen = async() => {
    try {
      if([5, 15].includes(route?.params?.gameTypeId)){
        document.body.requestFullscreen();
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
      }
    } catch (error) {
      console.error("Failed to lock orientation:", error);
    }
  };

  const unlockOrientation = async () => {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
    } catch (error) {
      console.error("Failed to unlock orientation:", error);
    }
  };

  useEffect(() => {
    lockScreen();
    bgsoundPause();
    onGModeRequest(route.params.id);
    return () => {
      unlockOrientation();
      onLeaveGModeRequest();
      bgsoundPlay();
    };
  }, []);

  return (
    <GModeProvider>
      <View style={styles.container}>
        <GameView />
        <Drawer />
        <CoinsDialog />
      </View>
    </GModeProvider>
  );
};

export default React.memo(GameMode);
