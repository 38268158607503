import _ from "lodash";
import Categories from "./mockdata/categories";

const sapphire = ["type4", "big", "type2", "medium", "type2", "big"];
const emerald = ["type4", "big", "type2", "medium", "type2", "big"];
const ruby = ["big", "type4", "big", "type2", "medium", "type2"];
const diamond = ["medium", "type4", "big", "type2", "medium", "type2"];
const arrayStone = [sapphire, emerald, ruby, diamond];

const groupAndSort = (arr: any) => {
  return new Promise((resolve, reject) => {
    const groupedData: any = _.cloneDeep(arr);
    const promises: any = [];

    _.forEach(groupedData, (groupObjects, index: number) => {
      const promise = new Promise((innerResolve) => {
        const transformedObjects = TransformGameList(
          groupObjects.games,
          arrayStone[index]
        );
        innerResolve(transformedObjects);
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((sortedGroups) => {
        resolve(sortedGroups);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const TransformGameList = (inputArray: any[], sequence: any) => {
  const oldGames = [...inputArray];
  const outputArray = [];
  const gameTypes = [5, 6, 7];
  let currentIndex = 0;

  // Mark all items with `isNew = 1` as `medium`
  oldGames.forEach(item => {
    if (item.isNew === 1) {
      outputArray.push({ ...item });
    }
  });

  // Remove processed items (isNew = 1) from `oldGames`
  const remainingGames = oldGames.filter(item => item.isNew !== 1);

  // Helper function to process chunks
  const processChunk = (chunkSize: number, type: string) => {
    const chunk = remainingGames.splice(0, chunkSize);

    // If the chunk is smaller than required, set all items to "medium"
    if (chunk.length < chunkSize) {
      chunk.forEach(item =>
        outputArray.push({ ...item, type: "medium" })
      );
      return;
    }

    outputArray.push({ type, child: chunk });
  };

  while (remainingGames.length > 0) {
    const type = sequence[currentIndex];

    if (type === "type4") {
      processChunk(4, type);
    } else if (type === "type2") {
      processChunk(2, type);
    } else {
      const item = remainingGames.shift();
      if (item) {
        outputArray.push({
          ...item,
          type: gameTypes.includes(item.gameTypeId) ? "medium" : type,
        });
      }
    }

    // Repeat the sequence
    currentIndex = (currentIndex + 1) % sequence.length;
  }

  return outputArray;
};

export const compileTransformation = async (inputArray: any) => {
  const groupAndSortResult: any = await groupAndSort(inputArray);

  const labels = [
    {
      label: "TIER 1",
      source: "tier_bronze",
    },
    {
      label: "TIER 2",
      source: "tier_bronze",
    },
    {
      label: "TIER 3",
      source: "tier_silver",
    },
    {
      label: "TIER 4",
      source: "tier_gold",
    },
  ];

  const combinedArray = _.flatMap(
    groupAndSortResult,
    (group: any, index: number) => {
      const label = labels[index];

      if (index > 0) {
        return [label, ...group];
      } else {
        return group;
      }
    }
  );

  return combinedArray;
};

// SWITCH GAMES BETWEEN FAVORITE/UNFAVORITE
export const switchSelectedFavoriteGames = (games: any, id: number) => {

  return games.map((game: any) => {
    if (game.child) {
      // Handle nested items
      return {
        ...game,
        child: game.child.map((item: any) => {
          if (item.id === id) {
            return { ...item, isFavourite: !item.isFavourite };
          }
          return item;
        })
      };
    } else if (game.id === id) {
      // Handle top-level items
      return { ...game, isFavourite: !game.isFavourite};
    }
    return game;
  });
};

// REPLACE STRING IN JSON/ARRAY FILE
export const replaceStringInJson = (jsonObj: any, oldString: string, newString: string)  =>{
  // Check if jsonObj is an object
  if (typeof jsonObj !== "object" || jsonObj === null) {
    return jsonObj;
  }

  // If jsonObj is an array, iterate through each element
  if (Array.isArray(jsonObj)) {
    for (let i = 0; i < jsonObj.length; i++) {
      jsonObj[i] = replaceStringInJson(jsonObj[i], oldString, newString);
    }
  } else {
    // If jsonObj is an object, iterate through each key-value pair
    for (const key in jsonObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (jsonObj.hasOwnProperty(key)) {
        // Recursively call replaceStringInJson for nested objects/arrays
        jsonObj[key] = replaceStringInJson(jsonObj[key], oldString, newString);

        // Check if the current value is a string and replace the oldString
        if (typeof jsonObj[key] === "string") {
          jsonObj[key] = jsonObj[key].replace(new RegExp(oldString, "g"), newString);
        }
      }
    }
  }

  return jsonObj;
};

// COMPARE TWO ARRAYS TO CHECK IF THE MISSION IS COMPLETED
export const compareMissionsBeforeAndAfter = (incompleteArray: any, completedArray: any) => {
  // Create a set of completed ids for faster lookup
  const completedIdSet = new Set(completedArray.map((item: any) => item.id));

  // Map over the incompleteArray and add isNewlyCompleted property
  const resultArray = incompleteArray.map((item: any) => {
    if (completedIdSet.has(item.id) && completedArray.find((completedItem: any) => completedItem.id === item.id && completedItem.status === "COMPLETED" && item.status === "ACTIVE")) {
      // If the id is found in the completedArray, and there is a status transition, add isNewlyCompleted property and adapt other properties
      const completedItem = completedArray.find((completedItem: any) => completedItem.id === item.id);
      return { ...item, hasKey: true, ...completedItem };
    } else {
      // If the id is not found in the completedArray or there is no status transition, keep the object unchanged
      return item;
    }
  });

  return resultArray;
};

// FILTER PRODUCTS
export const filterShopProducts = (data: any[] = []) => {
  return data
    .filter(item => item.isTopUp === true && item.isActive === 1)
    .sort((a, b) => a.description.localeCompare(b.description));
};

// UPDATE CATEGORIES
export const updatedCategories = (fetchCategories: any[]) => {
  return Categories.map((category) => {
    const fetchedCategory = fetchCategories.find(
      (fetched) => fetched.name === category.name
    );
    return {
      ...category,
      hasGames: fetchedCategory ? fetchedCategory.hasGames : category.hasGames,
    };
  });
};
