import { Platform } from "react-native";

export const isAndroidWeb = () => {
  if (Platform.OS === "web") {
    return /Android/i.test(navigator.userAgent);
  }
  return false;
};

export const isIOSWeb = () => {
  if (Platform.OS === "web") {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  return false;
};
